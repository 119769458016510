import React, { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { authInfo } from "./authInfo";
import { Link, useHistory } from "react-router-dom";
import styles from "./index.module.css";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/solid";
import {
  getLoginDetails,
  getProfile,
  isInRole,
  logout,
} from "../utils/utils.js";

const MenuDropdown = ({ profile }) => {
  const loginDetails = getLoginDetails();
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left z-50">
        <div>
          <Menu.Button className={styles.menuButton}>
            <>
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-6 w68 rounded-full" />
              {/*User icon for sms verification was being generated with '1' */}
              {/*<img*/}
              {/*    className="h-8 w-8 rounded-full"*/}
              {/*    src={user.picture}*/}
              {/*    alt="img"*/}
              {/*    title={user.email}*/}
              {/*/>*/}

              <ChevronDownIcon
                className="w-5 h-5 -mr-1 text-white-200 hover:text-white-100"
                aria-hidden="true"
              />
            </>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={styles.subMenu}>
            <div className="px-1 py-1 ">
              {loginDetails.userType == "AppUser" && (
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/profile" className={`${styles.menuItem}`}>
                      Profile
                    </Link>
                  )}
                </Menu.Item>
              )}
              {loginDetails.userType == "AppUser" ? (
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/tenants" className={`${styles.menuItem}`}>
                      Switch Tenants
                    </Link>
                  )}
                </Menu.Item>
              ) : (
                ""
              )}
              {loginDetails.userType == "AppUser" &&
              isInRole(profile, "GlobalAdmin") ? (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/global/settings"
                      className={`${styles.menuItem}`}
                    >
                      Global Admin
                    </Link>
                  )}
                </Menu.Item>
              ) : (
                ""
              )}
              <Menu.Item>
                {({ active }) => (
                  <a onClick={logout} className={`${styles.menuItem}`}>
                    Logout
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const AppUserNavigation = ({ profile }) => {
  const { tenantInfo } = getLoginDetails();
  return (
    <Fragment>
      {tenantInfo != null ? (
        <Fragment>
          <Link to="/requests" className="text-white">
            Requests
          </Link>
          <Link to="/accounts" className="text-white">
            Accounts
          </Link>
          {isInRole(profile, "TenantAdmin") && (
            <Link to="/settings" className="text-white">
              Admin
            </Link>
          )}
          <Link to="/requests/create" className={styles.menuPrimaryButton}>
            New Request
          </Link>
        </Fragment>
      ) : (
        <div className=" max-w-7xl mx-auto">
          <h1 className="text-2xl sm:text-2xl font-bold text-white">
            DocuPoint
            <span className="hidden sm:inline"> - Secure Document Portal</span>
          </h1>
        </div>
      )}
    </Fragment>
  );
};

const Nav = () => {
  const loginDetails = getLoginDetails();
  const tenantInfo = loginDetails.tenantInfo;
  const logoUrl = tenantInfo ? tenantInfo.logoUrl : "/logos/0";
  const title = tenantInfo
    ? tenantInfo.displayTenantName
      ? `${tenantInfo.name} - `
      : ""
    : "DocuPoint";
  const [profile, setProfile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const loadProfile = async () => {
      try {
        setProfile(await getProfile());
      } catch (e) {
        console.error(e);
        history.push("/profile-error");
      }
    };

    if (loginDetails.userType == "AppUser") {
      loadProfile();
    }
  }, []);

  return (
    <div className="bg-gray-800 pb-24 sm:pb-32">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="border-b border-gray-700">
                <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0 cursor-pointer">
                      <Link to="/">
                        <img
                          className="brandingLogo"
                          src={logoUrl}
                          alt="logo"
                        />
                      </Link>
                    </div>

                    {loginDetails.userType == "AppUser" ? (
                      <Fragment>
                        {profile && <AppUserNavigation profile={profile} />}
                      </Fragment>
                    ) : (
                      <div className=" max-w-7xl mx-auto">
                        <h1 className="text-2xl sm:text-2xl font-bold text-white">
                          {title}
                          <span className="hidden sm:inline">
                            Secure Document Portal
                          </span>
                        </h1>
                      </div>
                    )}
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {loginDetails.userType == "AppUser" && profile ? (
                        <div className="text-white leading-tight text-sm">
                          <div className="text-right">{profile.name}</div>
                          {loginDetails.tenantInfo != null ? (
                            <div className="text-right">
                              {loginDetails.tenantInfo.name}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {(loginDetails.userType == "Account" || profile) && (
                        <MenuDropdown profile={profile} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <header className="py-4 sm:py-10 invisible sm:visible"></header>
    </div>
  );
};

export { Nav };
