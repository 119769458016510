import React, { Fragment, useContext, useEffect } from "react";
import { Nav } from "./Nav";
import { Footer } from "./footer";
import { ToastContainer } from "react-toastify";
import { ThemedButton } from "./buttons/themedButton";
import { Link } from "react-router-dom";
import { clearProfile, getProfile } from "../utils/utils";

const DocuPointLayout = ({
  backgroundColor = "bg-white",
  unauthorized = false,
  children,
}) => {
  useEffect(() => {
    if (unauthorized) {
      clearProfile();
      getProfile();
    }
  });

  return (
    <div>
      <Nav />
      <main className="-mt-40 ">
        <div className="max-w-7xl mx-auto pb-6 sm:px-6 lg:px-8">
          <div
            id="bodyFrame"
            className={`${backgroundColor} sm:rounded-lg sm:shadow sm:px-5 sm:py-6`}
          >
            {unauthorized ? <NotAuthorized /> : children}
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
};

const NotAuthorized = () => {
  return (
    <Fragment>
      <div>It looks like you are not authorized to view this page.</div>
      <div className="mt-4">
        <Link to="/">
          <ThemedButton fitted={true}>Return Home</ThemedButton>
        </Link>
      </div>
    </Fragment>
  );
};

export { DocuPointLayout };
